<template>
  <b-icon
    icon="info-circle-fill"
    scale="1"
    variant="primary"
    class="icon-help"
    v-b-tooltip.v-secondary.nonInteractive="
      `${tooltip_text}
      `
    "
  ></b-icon>
</template>

<script>
export default {
  name: "InfoTooltip",
  props: {
    tooltip_text: {
      type: String,
      required: true,
    },
  },
  methods: {},
};
</script>

<style scoped>
.icon-help {
  cursor: help;
}
</style>